.images fieldset.inputs {
  & input, & textarea {
    width: 400px;
  }
}

table.images td {
  vertical-align: middle !important;

  & img {
    width: 100px;
  }
}

.new-images {
  & .pull-left, & .pull-right {
    width: 49%;

    & form {
      width: 100%;
    }
  }

  & .dropzone {
    margin-top: 24px;
    margin-bottom: 32px;

    & .hidden {
      display: none;
    }

    & .dz-credit, & .dz-description {
      text-decoration: underline;
    }
  }
}

#image-preview {
  & img {
    width: 100%;
  }
}

img[data-toggle="modal"] {
  cursor: pointer;
}

.document_image, .document_home_image, .document_video {
  & .select2-container .select2-selection--single {
    height: auto;
    min-height: 28px;
  }
}
