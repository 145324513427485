#duplicates {
  & .simple-form {
    width: 100%;

    & .checkbox {
      min-height: 0;
      margin: 0;

      & input[type="checkbox"] {
        position: static;
        margin: 0;
      }
    }
  }

  & tbody {
    border: none;

    &.group:before {
      content: '';
      display: block;
      height: 10px;
    }
  }
}
