table {
  & .top-level-hint {
    font-style: italic;
    color: #777;
  }

  & .draggable i {
    cursor: move;
  }

  & .ui-draggable-dragging {
    background-color: transparent !important;
  }
}
