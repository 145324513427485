#wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.site-header {
  & h1 {
    & a, & a:visited, & a:hover, & a:active {
      text-decoration: none;
      color: inherit;
    }
  }

  & .login-bar {
    width: 100%;
    text-align: right;

    & .link:before {
      padding: 0 5px;
      color: #ccc;
      content: "|";
    }
  }
}

.top-btn {
  margin-bottom: 10px !important;
  margin-right: 10px;
}

.bottom-btn {
  margin-top: 10px !important;
}

nav.pagination {
  margin: 0 0 20px 0;
  float: left;
}

.pagination-options {
  margin: 0 0 20px 0;
  float: right;

  & select, & input {
    vertical-align: top;
    width: auto;
    font-size: 0.9em;
    margin-bottom: 0;
    padding: 0
  }
}

.help-block {
  font-size: 0.9em;
  color: gray;
  font-style: italic;
}

.error-block {
  font-size: 0.9em;
  font-style: italic;
}

table {
  & .snug {
    width: 1px;
    white-space: nowrap;
    text-align: center;

    &.wrap {
      white-space: pre;
    }
  }
}

.tooltip {
  white-space: pre;
}

.footer {
  text-align: center;
  color: #999;
  font-size: 0.8em;
  padding-top: 20px;
  clear: both;
}

.help-inline {
  font-style: italic;
}

.halfsize {
  width: 50%;
}

.delete-button {
  margin-left: 80px;
}

.alert {
  margin-bottom: 10px;
}

.breadcrumb {
  margin-bottom: 10px;
}

h1 small {
  font-size: 50%;
}

.alert {
  padding: 10px;
}

.cms-search-bar {
  float: right;

  & > * {
    display: inline-block;
  }
}
