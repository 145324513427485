.videos {
  & .column {
    width: 49%;

    & .simple-form {
      width: 100%;
    }

    & #locus-videos {
      margin-top: 20px;
    }

    & .media-list {
      margin-top: 20px;

      & .youtube-video, & .locus-video {
        cursor: pointer;

        & img {
          max-width: 200px;
        }
      }
    }
  }
}
