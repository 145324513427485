#shortlink_short_url {
  width: auto;
  display: inline-block;
  margin-left: 10px;
}

.shortlink_short_url .controls::before {
  content: "pcg.church/go/";
  display: inline-block;
}

.shortlink_posting {
  margin-top: 15px;
}
