.btn {
  padding: 8px 16px;
}

.btn-xs {
  padding: 4px 8px;
}

.table {
  font-size: inherit;
}

.breadcrumb {
  font-size: 0.8em;
}

/* The editor doesn't have a small-caps button, so we're going to appropriate sup */
sup {
  top: auto;
  font-size: inherit;
  line-height: normal;
  font-variant: small-caps;
}

.form-group {
  &.date {
    & select.date {
      width: auto;
      display: inline-block;
    }
  }
}

.modal {
  z-index: 1050 !important;
}
