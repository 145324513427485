.image-select-option {
  margin: 5px 0;
}

.video-select-option {
  margin: 5px 0;
  max-width: 100px;
  max-height: 100px;
}

.image-select .select2-choice {
  height: auto;
}

.image-select, .video-select {
  & + .select2-container .select2-selection--single {
    height: auto !important;
    min-height: 28px;
  }
}
