#slideshow-left-column, #collection-left-column {
  width: 50%;
  padding-right: 10px;
  float: left;
}

#slideshow-right-column, #collection-right-column {
  width: 50%;
  padding-left: 10px;
  float: left;
}
