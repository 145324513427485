.document-container {
  display: table;
  width: 100%;
}

.document-main {
  display: table-cell;

  & table .symbol {
    display: inline-block;
    line-height: 0.8em;
    font-size: 1.5em;
  }
}

.toggle {
  cursor: pointer;
  user-select: none;

  & i {
    margin-left: 5px;
  }
}

.document-info {
  padding-left: 20px;
  display: table-cell;
  width: 300px;

  & th {
    text-align: center;
  }

  & tr {
    & td:first-child {
      width: 45%;
    }
  }

  & td {
    font-size: 12px;
    padding: 3px;
    text-align: left;
  }
}

.document-delete {
  text-align: center;
}

.form-group.datetimepicker {
  width: 50%;
}

.disabled {
  color: gray;
  font-style: italic;
}

div.document_written_at, div.document_hide_day {
  display: inline-block;
}

div.document_written_at {
  margin-right: 30px;
}

.publish-date-scope {
  position: relative;
}

#postings-table td {
  vertical-align: middle;
}
