.simple-form {
  width: 50%;

  & .required label > abbr {
    color: red;
    font-size: 0.8em;
    vertical-align: top;
    font-style: italic;
  }

  & .select2-container {
    width: 100%;

    &.form-control {
      border: none;
      box-shadow: none;
      padding: 0;
      height: auto;
    }
  }

  & label.radio {
    & input.radio_buttons {
      width: auto;
    }
  }

  & .boolean-select {
    width: auto;
  }
}

label.boolean {
  margin-left: 20px;
}
