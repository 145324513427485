.link-with-icon {
  text-align: center;
  display: inline-block;
  padding: 0 20px 30px 20px;

  & a {
    text-decoration: none;
  }

  & .title {
    color: #222;
  }
}
